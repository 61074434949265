var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Ventancat")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Bienvenido a Ventancat! 👋 ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"success","block":""},on:{"click":_vm.validationForm}},[_vm._v(" Entrar ")]),_c('b-button',{attrs:{"type":"button","variant":"primary","block":"","link":"","to":"/register"}},[_vm._v(" Registrarse ")]),_c('b-button',{attrs:{"type":"button","variant":"warning","block":"","link":"","to":"/recover"}},[_vm._v(" Recuperar Contaseña ")]),_c('div',{staticClass:"text-center boxPolities"},[_c('b-nav',[_c('b-nav-item',{attrs:{"href":'politica-cookies/'}},[_vm._v("Política de cookies")]),_c('b-nav-item',{attrs:{"href":'politica-privacidad/'}},[_vm._v("Política privacidad")]),_c('b-nav-item',{attrs:{"href":'politica-desistimiento/'}},[_vm._v("Derecho desestimiento")]),_c('b-nav-item',{attrs:{"href":'terminos-condiciones/'}},[_vm._v("Términos y Condiciones")])],1)],1)],1)],1)],1)],1)],1),_c('div',[_c('b-modal',{attrs:{"id":"modal-1","title":"Aviso de Cookies"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.aceptar()}}},[_vm._v(" Aceptar ")]),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancelar ")])]}}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('p',{staticClass:"my-4"},[_vm._v(" Utilizamos cookies propias y de terceros para mejorar tu experiencia de navegación, y ofrecer contenidos de interés. Has click para aceptar nuestra política de cookies. "),_c('a',{attrs:{"href":'politica-cookies/'}},[_vm._v("Leer más")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }